<template>
    <div class="card card-custom">

        <div class="card card-custom mb-5">
            <div class="card-body">
                <div class="m-form m-form--fit m--margin-bottom-20">
                    <div class="row">
                        <div class="form-group col-md-6 mt-5">
                            <label>{{$t('export_import.export_template')}}</label>
                            <div>
                                <b-button class="mt-auto mr-2" variant="primary" @click="getFileTemplate">
                                    <i v-if="is_requesting" class="fa fa-spinner fa-pulse fa-fw"></i>
                                    <i v-else class="fas fa-file-export"></i>
                                    {{$t('export_import.export')}}</b-button>
                            </div>
                        </div>
                        <div class="form-group col-md-6 mt-5">
                            <label>{{$t('export_import.import_data')}}</label>
                            <div class="form-group">
                                <upload-file-excel
                                        @file="listenerAttachment"
                                        @errors="listenerErrors"
                                        :inner-id="'upload_file'"
                                        :placeholder="$t('export_import.upload_file')"
                                        :upload="dir_upload"
                                        v-bind:valueprop="{name:'file',path:file_url}" v-if="reloadUploadAttachment">
                                </upload-file-excel>
                            </div>
                        </div>
                        <div class="form-group col-md-12 mt-5">
                            <h5>{{$t('import_status')}}</h5>
                        </div>
                        <div class="form-group col-md-12 mt-5" v-if="errors_list.length > 0">
                            <table class="table table-bordered">
                                <thead>
                                <tr>
                                    <th>{{$t('export_import.row')}}</th>
                                    <th>{{$t('export_import.attribute')}}</th>
                                    <th>{{$t('export_import.errors')}}</th>
                                    <th>{{$t('export_import.values')}}</th>
                                </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(error, index) in errors_list" :key="index">
                                        <td>{{error.row?error.row:''}}</td>
                                        <td>{{error.attribute?error.attribute:''}}</td>
                                        <td>
                                            <div class="d-block w-100">
                                                <ul class="list-unstyled">
                                                    <li v-for="(err, _ind) in error.errors" :key="_ind">{{err}}</li>
                                                </ul>
                                            </div>

                                        </td>
                                        <td>{{error.values}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="form-group col-md-12 mt-5" v-else>
                            <p class="text-center">{{$t('no_errors')}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";
    export default {
        name: "Index",
        data(){
            return{
                mainRouteDependency: 'base/dependency',
                dir_upload: null,
                reloadUploadAttachment: true,
                file_url: null,
                is_requesting: false,
                errors_list: [],
            }
        },
        methods:{
            listenerAttachment() {

            },
            listenerErrors(errors) {
                this.errors_list = errors ? errors : [];
            },
            getFileTemplate(){
                this.is_requesting = true;
                ApiService.get(this.mainRouteDependency + "/template/items").then((response) => {
                    this.is_requesting = false;
                    if (response.data.data.link)
                        window.open(response.data.data.link, '_blank');
                }).catch(()=>{
                    this.is_requesting = false;
                });
            }
        },
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.export_import_items")}]);
        },
    }
</script>

<style scoped>

</style>